import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import SC from '../Vouchers/Account/elements'
import useVoucherCodeHook from '../Vouchers/Account/useVoucherTabHook'
import VoucherDetailsPopUp from '../Vouchers/Account/VoucherDetailsPopUp'
import { AccountConsumer } from '../../components/AccountProvider'
import Text from '../../components/Text/Text'
import EmptyIcon from './assets/EmptyVoucher.svg'
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import VirtualCarousel from '../VirtualCarousel/VirtualCarousel'
import SideSheet from '@ntuctech/devex-tangram/SideSheet/SideSheet'
import { SCREEN_SIZE } from '../../lib/Media'
import VoucherCard from './VoucherCard'
import { capitalizeFirstLetterLowercaseOther } from '../../lib/stringHelperFunctions'
import { TRACKER_CONSTANTS } from '../Vouchers/utils/vouchersTracker'

const VoucherTitle = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #333333;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  float: right;
`

const EmptyTitle = styled.div`
  width: 11rem;
  height: 1.5rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #333333;
  flex: none;
  order: 0;
  flex-grow: 0;
`

const EmptySubTitle = styled.div`
  width: 11rem;
  height: 2.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #696969;
  flex: none;
  order: 1;
  flex-grow: 0;
`

const EmptyContainer = styled.div`
  box-sizing: border-box;
  width: 20.5rem;
  height: 6.25rem;
  left: 1rem;
  background: #ffffff;
  border: 0.063rem solid #eaeaea;
  border-radius: 0.5rem;
  padding: 1rem;
`

const VoucherSwimLaneContainer = styled.div`
  margin-bottom: 1rem;
`

const SwimlaneTitle = styled.h2`
  font-weight: 700;
  color: #333333;
  font-size: 1.25rem;
  line-height: 1.4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 1rem;
  }
`

const VoucherSwimlaneContainer = ({ track, data }) => {
  const [state, dispatch] = useVoucherCodeHook()
  const [voucherData, setVoucherData] = useState([])
  const [openSideSheet, setOpenSideSheet] = useState(false)
  const activeIndex = parseInt(Object.keys(state.activeFilter)[0])
  const FILTER_ERROR = state.filteredData?.length < 1 && activeIndex > 0
  const activeDataList = state.activeData?.list
  const isEmpty = voucherData.length === 0

  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (state?.activeData?.list?.length > 0 && isEmpty) {
      setVoucherData(
        state.activeData.list.filter(item =>
          item.tags.some(tag => tag === 'Online')
        )
      )
    }

    track({
      [GEV.SCREEN_NAME]: 'Home',
      [GEV.PAGE_TYPE]: 'Home',
      [GEV.CATEGORY]: 'grocery-home-voucher',
      [GEV.EVENT_ACTION]: 'PromotionImpressions',
      [GEV.EVENT_LABEL]: 'Content',
      [GEV.EVENT]: 'hm_push_event',
      [GEV.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
    })
  }, [state.activeData.list, track, voucherData, isEmpty])

  useEffect(() => {
    setOpenSideSheet(Object.keys(state.voucherDetails).length > 0)
  }, [state.voucherDetails])

  const isCampaignSaveEveryday = window.location.href.endsWith(
    'campaign-save-everyday'
  )
  const currentDate = new Date()

  return isMounted ? (
    <VoucherSwimLaneContainer className="myrewards-tab">
      <VoucherTitle>
        <SwimlaneTitle>
          {data?.title
            ? capitalizeFirstLetterLowercaseOther(data.title)
            : 'Vouchers available'}
        </SwimlaneTitle>{' '}
        {voucherData.length > 3 ? (
          <Link
            href={
              isCampaignSaveEveryday
                ? `/save-everyday-${currentDate.getFullYear()}`
                : '/accounts?tab=vouchers&filter=Delivery'
            }
            passHref
            prefetch={false}
            legacyBehavior
          >
            <StyledLink
              onClick={() => {
                localStorage.setItem('isOGVoucher', true)
                track({
                  [GEV.SCREEN_NAME]: 'Home',
                  [GEV.PAGE_TYPE]: 'Home',
                  [GEV.CATEGORY]: 'Home',
                  [GEV.EVENT_ACTION]: 'PromotionClick',
                  [GEV.EVENT_LABEL]: 'See-all',
                  [GEV.EVENT]: 'hm_push_event',
                  [GEV.TRACK]: TRACKER_CONSTANTS.TRACK_PARAM,
                })
              }}
              as="a"
              size="medium"
              color="#1557bf"
              weight="bold"
              data-testid="see-all-og-swimlane"
            >
              See all
            </StyledLink>
          </Link>
        ) : (
          ''
        )}
      </VoucherTitle>
      {isEmpty ? (
        <EmptyContainer>
          <SC.Grid container>
            <SC.Grid item xs={7}>
              <EmptyTitle>Better luck tomorrow!</EmptyTitle>
              <EmptySubTitle>Check back daily for more vouchers</EmptySubTitle>
            </SC.Grid>
            <SC.Grid item xs={4}>
              <img src={EmptyIcon} alt="Empty Voucher" />
            </SC.Grid>
          </SC.Grid>
        </EmptyContainer>
      ) : (
        <SC.OGVoucherContainer>
          <SC.Grid container className="voucher-container">
            <VirtualCarousel
              items={
                isCampaignSaveEveryday
                  ? voucherData
                      .filter(voucher => voucher.code.startsWith('SEC'))
                      .slice(0, 20)
                  : voucherData
              }
              staticHeight={{
                [SCREEN_SIZE.From.MobileL]: 110,
                [SCREEN_SIZE.Below.MobileL]: 110,
              }}
              staticWidth={{
                [SCREEN_SIZE.From.MobileL]: 366,
                [SCREEN_SIZE.Below.MobileL]: 382,
              }}
            >
              {({ item, index }) => {
                return (
                  <VoucherCard
                    i={index}
                    item={item}
                    error={activeDataList?.length < 1 || FILTER_ERROR}
                    loading={state.loading}
                    dispatch={dispatch}
                    imgLoading={state.loading}
                    filterValue={state.activeFilter}
                    showFilter={state.showFilter}
                    track={track}
                    isVoucher
                  />
                )
              }}
            </VirtualCarousel>
          </SC.Grid>
        </SC.OGVoucherContainer>
      )}
      <SideSheet
        data-testid="voucher-sidesheet"
        open={openSideSheet}
        onClose={() => setOpenSideSheet(false)}
        direction="right"
        title="About this voucher"
        size={300}
        enableOverlay
        lockBackgroundScroll
        description=""
      >
        <SC.GlobalStyle openSideSheet={openSideSheet} />
        <VoucherDetailsPopUp
          dispatch={dispatch}
          voucherDetails={state.voucherDetails}
          pastTab={state.activeTab > 0}
          showQrCode={state.showQrCode}
          showHowToUse={state.showHowToUse}
          handleClose={dispatch.onHandleClose}
          imgLoading={state.imgLoading}
          isSideSheet={true}
        />
      </SideSheet>
    </VoucherSwimLaneContainer>
  ) : null
}

const VoucherSwimlane = props => (
  <EventTrackingConsumer>
    {({ track }) => (
      <AccountConsumer>
        {({ isLoggedIn }) =>
          isLoggedIn ? (
            <VoucherSwimlaneContainer
              {...props}
              track={track}
              isLoggedIn={isLoggedIn}
            />
          ) : null
        }
      </AccountConsumer>
    )}
  </EventTrackingConsumer>
)

export default VoucherSwimlane
